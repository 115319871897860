import {
  TextInput,
  Button,
  PasswordInput,
  Text,
  Paper,
  ButtonProps,
  Image,
  Anchor,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import useUser from '../../hooks/useUser';
import { handleLoginError } from '@/utils/login';
import GoogleIcon from './GoogleIcon';
import ForgotPasswordModal from './ForgotPasswordModal';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { forgotPasswordModalOpenedState, showSignInState } from './atoms';
import logoImg from '@/images/logo.svg';
import logoImgWhite from '@/images/logoWhite.svg';

function AuthForm() {
  const { user, login, isAuthenticating, loginGoogle } = useUser();
  const togglePasswordModal = useSetRecoilState(forgotPasswordModalOpenedState);
  const [showSignIn, setShowSignIn] = useRecoilState(showSignInState);

  const form = useForm({
    initialValues: {
      password: '',
      email: '',
    },
  });

  const handleFormSubmit = () =>
    form.onSubmit((values) => {
      login(
        {
          email: values.email.replace(/\s+/g, ''),
          password: values.password,
        },
        {
          onError: handleLoginError,
          onSuccess: () => {},
        },
      );
    });

  const termsAndConditions = (
    <Text size="sm" align="center" mt={10}>
      By signing in, you agree to our
      <Text>
        <Anchor
          href="https://app.termly.io/document/terms-of-service/a118a575-bf92-4a88-a954-1589ae572d09"
          target="_blank">
          Terms of Service
        </Anchor>{' '}
        and{' '}
        <Anchor
          target="_blank"
          href="https://app.termly.io/document/privacy-policy/c16d10b8-1b65-43ea-9568-30e7ce727a60">
          Privacy Policy.
        </Anchor>
      </Text>
    </Text>
  );

  return (
    <Paper>
      <ForgotPasswordModal />
      <div className="w-[150px]">
        <Image src={logoImgWhite} />
      </div>

      <a className="mt-2 mb-2 cursor-pointer block">
        <Text>
          Don't have an account ? <Anchor href="/register">{'Sign up'}</Anchor>
        </Text>
      </a>
      <form onSubmit={handleFormSubmit()}>
        <GoogleButton
          onClick={() => {
            loginGoogle();
          }}
          fullWidth
          size="md">
          {'Continue with Google'}
        </GoogleButton>
        <div>
          <Text my={2} ta="center">
            {'or'}
          </Text>
        </div>
        {!showSignIn && (
          <>
            <a href="/register">
              <ContinueWithEmail size="md" fullWidth>
                {'Create account'}
              </ContinueWithEmail>
            </a>
            {termsAndConditions}
            <Text fw={500} size="xl" pt={40} mb={5}>
              Already have an account?{' '}
            </Text>
          </>
        )}

        {!showSignIn && (
          <Button
            onClick={() => {
              setShowSignIn(true);
            }}
            variant="filled"
            color="ocean-blue"
            size="md"
            fullWidth>
            {'Sign in'}
          </Button>
        )}
        {showSignIn && (
          <>
            <TextInput
              required
              type={'email'}
              placeholder="Email address"
              size="md"
              {...form.getInputProps('email')}
            />
            <PasswordInput
              required
              placeholder="Your password"
              mt={5}
              size="md"
              {...form.getInputProps('password')}
            />
            <Button
              variant="white"
              color="black"
              type="submit"
              loading={isAuthenticating}
              fullWidth
              mt={5}
              size="md">
              Sign in
            </Button>
            <a
              onClick={() => togglePasswordModal(true)}
              className="mt-2 cursor-pointer block text-center">
              <Text size="sm" c="dimmed">
                {'Reset password'}
              </Text>
            </a>
            {termsAndConditions}
          </>
        )}
      </form>
    </Paper>
  );
}

export function GoogleButton(props: ButtonProps) {
  return (
    <Button
      leftSection={<GoogleIcon />}
      variant="default"
      color="gray"
      {...props}
    />
  );
}

export function ContinueWithEmail(props: ButtonProps) {
  return <Button variant="default" color="gray" {...props} />;
}

export default AuthForm;
